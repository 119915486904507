import React, { useContext, useEffect } from "react";
import Navbar from "../components/navbar.js";
import AllUsers from "../pages/allUsers.js";
import CreateNewPOS from "../pages/createNewPOS.js";
import { AuthContext } from "../auth/AuthProvider.js";
import { Route, Routes } from "react-router-dom";
import { DataContext } from "../auth/DataProvider.js";

const Layout = () => {
  const { user } = useContext(AuthContext);
  const { allUsers, fetchAllUsers, setAllUsers, isLoading } =
    useContext(DataContext);

  useEffect(() => {
    fetchAllUsers();
  }, [user]);

  const handleUserChange = (changedUser) => {
    if (changedUser.deleted) {
      setAllUsers((prevUsers) =>
        prevUsers.filter((user) => user.id !== changedUser.id)
      );
    } else {
      setAllUsers((prevUsers) => {
        const index = prevUsers.findIndex((user) => user.id === changedUser.id);
        if (index !== -1) {
          return prevUsers.map((user) =>
            user.id === changedUser.id ? changedUser : user
          );
        } else {
          return [...prevUsers, changedUser];
        }
      });
    }
  };

  return (
    <div className="w-screen h-screen" style={{ backgroundColor: "#F8F8F8" }}>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={<AllUsers allUsers={allUsers} isLoading={isLoading} />}
        />
        <Route
          path="/create"
          element={
            <CreateNewPOS
              onUserChange={handleUserChange}
              onDeleteUser={handleUserChange}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default Layout;
