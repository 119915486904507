import React from "react";
import { GoArrowRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";

const User = (props) => {
  const navigate = useNavigate();

  const handleUpdate = () => {
    navigate(`/admin/create`, { state: { userData: props.user } });
  };

  return (
    <div
      onClick={handleUpdate}
      className="rounded-2xl hover:cursor-pointer"
      style={{
        width: "386px",
        height: "289px",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <img
        className="profile-photo rounded-2xl"
        src={props.user.imageUrl}
        alt={"Shua's Canteen"}
        style={{ width: "100%", height: "100%", objectFit: "content" }}
      />

      <div
        style={{
          width: "386px",
          height: "94px",
          position: "absolute",
          bottom: 0,
          left: 0,
          backgroundColor: "#f1f1f1",
          paddingLeft: "24px",
          paddingBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <div
            style={{
              marginTop: "8px",
              color: "#000000",
              fontWeight: 500,
              height: "21px",
            }}
          >
            {props.user.title}
          </div>
          <div style={{ color: "#919191", fontWeight: 400, height: "16px" }}>
            {props.user.subTitle}
          </div>
        </div>
        <GoArrowRight
          style={{
            width: "29px",
            height: "26px",
            border: "3px",
            marginTop: "34px",
            marginBottom: "34px",
            marginRight: "44px",
            color: "##33363F",
          }}
        />
      </div>
    </div>
  );
};

export default User;
