import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import lock from "../assets/lock.svg";
import person from "../assets/user.svg";
import { AuthContext } from "../auth/AuthProvider.js";
import toast from "react-hot-toast";
import { Spinner } from "@chakra-ui/react";
import CAMPOS from "../assets/CAMPOS.svg";

export default function Login() {
  const { loginUser, loading, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    userName: "",
    password: "",
  });

  // if (loading) {
  //   return (
  //     <div className="flex items-center justify-center h-screen">
  //       <Spinner />
  //     </div>
  //   );
  // }

  if (user) {
    navigate("/admin");
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!loginData.userName || !loginData.password) {
      toast.error("Username and password are required");
      return;
    }

    try {
      toast.loading("Please wait...");
      await loginUser(loginData.userName, loginData.password);
      toast.dismiss();
      toast.success("Login successful!");
      setTimeout(() => {
        navigate("/admin");
      }, 2000);
    } catch (error) {
      toast.dismiss();
      const errorCode = error.code;
      console.log(errorCode);
      let errorMessage = "An error occurred. Please try again.";

      switch (errorCode) {
        case "auth/invalid-email":
          errorMessage = "Invalid email address format.";
          break;
        case "auth/invalid-credential":
          errorMessage = "Invalid credential";
          break;
        case "auth/user-not-found":
          errorMessage = "No user found with this email.";
          break;
        case "auth/wrong-password":
          errorMessage = "Incorrect password.";
          break;
        case "auth/network-request-failed":
          errorMessage = "Network error. Please check your connection.";
          break;
        default:
          errorMessage = error.message;
          break;
      }
      toast.error(errorMessage);
      console.log(errorCode, errorMessage);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin(e);
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center h-[100vh] w-full"
      style={{ backgroundColor: "#F8F8F8" }}
    >
      <div>
        <img
          src={CAMPOS}
          alt="CAMPPOS"
          style={{ width: "226px", height: "78px" }}
        />
      </div>
      <div className="d-flex flex-column justify-around w-[364px] h-[264px]"
      style={{borderRadius:"16px 0px 0px 0px"}}>
        <div className="d-flex justify-center items-center h-[30px]" style={{fontSize:"30px", fontWeight:"700"}}>POS MASTER LOGIN</div>
        <div className="d-flex items-center h-[52px]" style={{gap:"2px", borderRadius:"16px", background:"#E06F2C0F"}}>
          <span className="d-flex justify-center w-[24px] h-[24px] ml-[10px]">
            <img src={person} width="16px" height="21px" alt="user icon" />
          </span>
          <span className="d-flex justify-center items-center">
            <input
              className="focus:outline-none"
              autoFocus
              type="text"
              placeholder="Username"
              value={loginData.userName}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              name="userName"
              size="18"
              style={{
                border: "none",
                backgroundColor: "transparent",
                padding: "5px",
              }}
              required={true}
            />
          </span>
        </div>
        <div className="d-flex items-center h-[52px]" style={{gap:"2px", borderRadius:"16px", background:"#E06F2C0F"}}>
          <span className="d-flex justify-center w-[24px] h-[24px] ml-[10px]">
            <img src={lock} width="16px" height="21px" alt="lock icon" />
          </span>
          <span className="d-flex justify-center items-center">
            <input
              className="focus:outline-none"
              type="password"
              placeholder="Password"
              value={loginData.password}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              name="password"
              size="18"
              style={{
                border: "none",
                backgroundColor: "transparent",
                padding: "5px",
              }}
              required={true}
            />
          </span>
        </div>
        <div className="d-flex justify-center mt-[5px]">
          <button
            className="w-[124px] h-[52px]"
            style={{ fontWeight: 700, size: "12px", color: "#FFFFFF",
            borderRadius: "16px",
            background: "linear-gradient(99.78deg, #E06F2C -5.85%, #FF9F66 109.55%)",
            boxShadow:"0px 8px 21px 0px #00000029"
             }}
            onClick={handleLogin}
          >
            Login now
          </button>
        </div>
      </div>
    </div>
  );
}