import React from "react";

const InputField = (props) => {
  return (
    <div className="relative w-full h-full" style={{ borderRadius: "16px" }}>
      {props.leading && (
        <div
          className="absolute left-[18px] top-1/2 transform -translate-y-1/2"
          style={{ color: "#1C1C1C" }}
        >
          {props.leading}
        </div>
      )}
      <input
        type={props.type}
        placeholder={props.placeholder}
        className={`w-full h-full px-[28px] custom-placeholder focus:outline-none ${
          props.leading ? "pl-[48px]" : ""
        } `}
        style={{ borderRadius: "16px" }}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
};

export default InputField;
