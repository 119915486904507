import { useContext } from "react";
import { AuthContext } from "../../auth/AuthProvider.js";
import { Outlet, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Spinner } from "@chakra-ui/react";

const PrivateRoute = () => {
  const { loading, user } = useContext(AuthContext);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  if (user) {
    return <Outlet />;
  }

  return <Navigate to="/" />;
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

export default PrivateRoute;
