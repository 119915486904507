import React from "react";
import User from "../components/user.js";
import { Grid } from "@chakra-ui/react";
import LoadingSpinner from "../components/common/loadingSpinner.js";

const AllUsers = ({ allUsers, isLoading }) => {

  return (
    <>
      <div className="flex justify-start sm:overflow-x-hidden w-[100%] overflow-y-auto h-[90%] custom-scrollbar py-8 px-5">
        <Grid
          className="w-full h-full"
          templateColumns={{
            md: "repeat(1, 1fr)",
            lg: "repeat(2, 1fr)",
            xl: "repeat(3, 1fr)",
          }}
          gap={12}
          p={4}
        >
          {allUsers.map((user, index) => (
            <User key={index} user={user} />
          ))}
        </Grid>
      </div>
      <LoadingSpinner isLoading={isLoading} />
    </>
  );
};

export default AllUsers;
