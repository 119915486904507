import React from "react";

const PortraitWarning = () => {
  return (
    <div className="portrait-warning">
      <p>Please rotate your device to landscape mode.</p>
    </div>
  );
};

export default PortraitWarning;
