import "./App.css";
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login.js";
import { ChakraProvider } from "@chakra-ui/react";
import "bootstrap/dist/css/bootstrap.min.css";
import PortraitWarning from "./PortraitWarning.js";
import NotPageFound404 from "./pages/notPageFound404.js";
import PrivateRoute from "./components/routes/PrivateRoute.js";
import Layout from "./components/layout.js";

function App() {
  const [isPortrait, setIsPortrait] = useState(
    window.innerHeight > window.innerWidth
  );

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ChakraProvider>
      <div>
        {/* {isPortrait ? (
          <PortraitWarning />
        ) : ( */}
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="admin/*" element={<PrivateRoute />}>
              <Route path="*" element={<Layout />} />
            </Route>
            <Route path="*" element={<NotPageFound404 />} />
          </Routes>
        {/* )} */}
      </div>
    </ChakraProvider>
  );
}

export default App;
