import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/AuthProvider.js";
import toast from "react-hot-toast";
import CAMPOS from "../assets/CAMPOS.svg";

const Navbar = (props) => {
  const { logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const showCreateNewPOS =
    location.state !== null || location.pathname === "/admin" ? true : false;

  const handleCreate = () => {
    navigate("/admin/create");
  };

  const handleLogout = async () => {
    try {
      toast.loading("Please wait...");
      await logoutUser();
      toast.dismiss();
      toast.success("Logout successful!");
    } catch (error) {
      toast.dismiss();
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className="w-full h-[10%] min-h-[60px] bg-body shadow bg-body-tertiary rounded">
      <div className="flex justify-between items-center h-[100%] sm:mx-5 mx-2">
        <div>
          <img
            src={CAMPOS}
            alt="CAMPPOS"
            className="w-[136px] h-[78px] sm:w-[226px] sm:h-[78px] sm:ml-10"
          />
        </div>

        <div className="flex justify-content-end">
          {showCreateNewPOS && (
            <button
              className="btn me-2"
              style={{ backgroundColor: "#FF8943", color: "#FFF" }}
              type="button"
              onClick={handleCreate}
            >
              Create new POS
            </button>
          )}
          <button
            className="btn sm:me-10 ms-2"
            type="button"
            style={{ color: "#FF8943", border: "1px solid #FF8943" }}
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
