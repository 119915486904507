import { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AuthContext } from "./AuthProvider.js";
import axios from "axios";

export const DataContext = createContext(null);

const DataProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAllUsers = async () => {
    try {
      if (allUsers.length === 0) {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_HOST_URL}/api/user/getAllUsers`,
          {
            headers: {
              Authorization: `Bearer ${user.accessToken}`,
            },
          }
        );
        setAllUsers(response.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const dataValue = {
    allUsers,
    fetchAllUsers,
    isLoading,
    setAllUsers,
  };

  return (
    <DataContext.Provider value={dataValue}>{children}</DataContext.Provider>
  );
};

DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DataProvider;
