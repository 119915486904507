import React from "react";

const DeleteContent = (props) => {
  return (
    <div className="w-[420px] h-[219px] flex items-center justify-center px-[80px] py-[43px]">
      <div className="flex flex-col justify-center items-center space-y-[36px] h-[100%] w-[100%]">
        <div
          className="w-[260px] h-[64px]"
          style={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: "21px",
            lineHeight: "31.5px",
            color: "#636363",
          }}
        >
          Are you sure you want to Delete this account
        </div>

        <div className="flex space-x-[11px]">
          <button
            className="btn"
            onClick={props.handleClose}
            style={{
              width: "87px",
              height: "31px",
              backgroundColor: "#E0E0E0",
              borderRadius: "5px",
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: "400",
              color: "#000000",
            }}
          >
            Cancel
          </button>
          <button
            className="btn"
            onClick={props.handleDelete}
            style={{
              width: "111px",
              height: "31px",
              backgroundColor: "#E06F2C",
              borderRadius: "5px",
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: "500",
              color: "#FFFFFF",
            }}
          >
            Yes, Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteContent;
